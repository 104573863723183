import { useContext, useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import axios from "axios"
import Cookies from "js-cookie"
import { jwtDecode } from "jwt-decode"
import { FaUser, FaPaw, FaCalendar, FaCreditCard } from "react-icons/fa"
import { IoTennisball } from "react-icons/io5"
import { MdAddPhotoAlternate, MdCardMembership } from "react-icons/md"
import {
	FaAnglesLeft,
	FaAnglesRight,
	FaUserDoctor,
	FaScissors,
} from "react-icons/fa6"
import { CiEdit } from "react-icons/ci"
import "react-big-calendar/lib/css/react-big-calendar.css"
import UpdateClientModal from "../../components/Modals/UpdateClient"
import UpdateVetModal from "../../components/Modals/UpdateVet"
import moment from "moment"
import PetDetails from "../ClientRegistration/PetDetails"
import LoadPage from "../../components/LoadingPage"
import ReleaseStatus from "./ReleaseFormsStatus"
import {
	yesOrNoOptions,
	genderOptions,
	petObj,
	petTypeOptions,
} from "../../utils/constants"
import ReleaseFormDaycare from "../ClientRegistration/ReleaseFormDaycare"
import ReleaseFormGrooming from "../ClientRegistration/ReleaseFormGrooming"
import PetPendingDetails from "../ClientRegistration/PetPendingDetails"
import { fileUploadHandler } from "../../utils/utils"
import Booking from "./booking"
import ClientAppointments from "../../components/ClientAppointments.js"
import { RegistrationContext } from "../../contexts/registrationStatusContext.js"
import Memberships from "../../components/Memberships"
import DaycareBookingForm from "../../components/DaycareBookingForm/index.js"

moment.locale("en-CA")
const CITY = localStorage.getItem("CITY")

const ClientDashboard = () => {
	const { setShowBookDaycareModal } = useContext(RegistrationContext)
	const [openModal, setOpenModal] = useState(false)
	const [vaccineImgType, setVaccineImgType] = useState("")
	const [openVaccineModal, setOpenVaccineModal] = useState(false)
	const [vaccineImg, setVaccineImg] = useState("")
	const [vetOpenModal, setVetOpenModal] = useState(false)
	const [releaseOpenModal, setReleaseOpenModal] = useState(false)
	const [signedReleaseForm, setSignedReleaseForm] = useState(false)
	const [addPetOpenModal, setAddPetOpenModal] = useState(false)
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)
	const [selectedPanel, setSelectedPanel] = useState("owner") // Default view
	const [isPanelOpen, setPanelOpen] = useState(true)
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [isPetButtonReady, setIsPetButtonReady] = useState(false)
	const clientCity = Cookies.get("client_location")

	const fetchData = async () => {
		try {
			const token = Cookies.get("authtoken")

			const decodedToken = jwtDecode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				window.location.href = "/"
				return
			}
			const clientLocation = Cookies.get("client_location")

			const response = await axios.get(
				`${
					clientLocation === "milton"
						? "/api/protected-data/"
						: "/api/cambridge-protected-data/"
				} `,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			setData(response.data.user)
		} catch (error) {
			console.log(error)
			if (error.response && error.response.status === 401) {
				setError("Unauthorized access")
			} else if (error.response && error.response.status === 400) {
				// window.location.href = "/"
			} else {
				// window.location.href = "/"
				console.error(error)
				setError("An error occurred")
			}
		}
	}

	useEffect(() => {
		if (localStorage.getItem("FOUND_ACCOUNT")) {
			localStorage.removeItem("FOUND_ACCOUNT")
		}

		fetchData()
	}, [])

	const handlePanelChange = (panel) => {
		setSelectedPanel(panel)
	}

	const togglePanel = () => {
		setPanelOpen(!isPanelOpen)
	}

	const redInputHandler = (value) => {
		return `${
			value == null || value == "" ? "border-red-input" : ""
		} mb-3 w-full text-black border-gray-300 rounded-md  focus:border-blue-500`
	}

	const handlePetChange = async (index, property, value) => {
		const petsArray = data.Pets || data.PetCambridges || []

		const updatedPetsArray = [...petsArray]

		if (index >= 0 && index < updatedPetsArray.length) {
			const updatedPet = { ...updatedPetsArray[index] }
			updatedPet[property] = value
			updatedPetsArray[index] = updatedPet

			if (data.Pets) {
				setData({
					...data,
					Pets: updatedPetsArray,
				})
			} else if (data.PetCambridges) {
				setData({
					...data,
					PetCambridges: updatedPetsArray,
				})
			}
		}
	}

	const updatePetHandler = async () => {
		const petsArray = data.Pets ? data.Pets : data.PetCambridges

		const dataObj = {
			clientID: data.id,
			pets: petsArray,
		}

		if (!data.signedDaycareRelease) {
			dataObj.signedDaycareRelease = true
		} else {
			dataObj.signedGroomingRelease = true
		}

		if (!signedReleaseForm) {
			return alert("You must check the release form checkbox.")
		}

		await Promise.all(
			petsArray.map(async (pet, index) => {
				const updatedImagesBordatella = await fileUploadHandler(
					index,
					[pet.bordatellaImgUrl],
					"bordatella"
				)

				const updatedImagesRegularShots = await fileUploadHandler(
					index,
					[pet.vaccineRecordImgUrl],
					"regularShots"
				)

				const foundPetBordatella = updatedImagesBordatella.find(
					(img) => img.type === "bordatellaImgUrl"
				)

				const foundPetRegularShots = updatedImagesRegularShots.find(
					(img) => img.type === "vaccineRecordImgUrl"
				)

				dataObj.pets[index] = {
					...pet,
					bordatellaImgUrl: foundPetBordatella
						? foundPetBordatella.url
						: pet.bordatellaImgUrl,
					vaccineRecordImgUrl: foundPetRegularShots
						? foundPetRegularShots.url
						: pet.vaccineRecordImgUrl,
				}
			})
		)

		await axios
			.put(`/api/clients/releaseUpdate/`, dataObj)
			.then(() => {
				setLoadingAxiosReq(false)
			})
			.catch((err) => {
				console.log(err)
				setLoadingAxiosReq(false)
			})
	}

	const updateOwnerRelease = async () => {
		const dataObj = {
			clientID: data.id,
			pets: data.Pets ? data.Pets : data.PetCambridges,
		}
		if (data && !data.signedGroomingRelease) {
			dataObj.signedGroomingRelease = true
		} else {
			dataObj.signedDaycareRelease = true
		}

		setLoadingAxiosReq(true)

		await updatePetHandler()

		await axios
			.put("/api/clients/releaseUpdate", dataObj)
			.then(() => {
				fetchData()
				setTimeout(() => {
					setLoadingAxiosReq(false)
					setReleaseOpenModal(false)
				}, 1000)
			})
			.catch((error) => {
				setLoadingAxiosReq(false)
				console.log(error)
			})
	}

	const handleImageUpload = async (e, PetId, imgKey) => {
		const file = e.target.files[0]
		const fd = new FormData()
		fd.append("file", file)
		fd.append("upload_preset", "amazing")
		try {
			setLoadingAxiosReq(true)
			const res = await axios.post(
				"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
				fd
			)
			let newPetImg = {
				[imgKey]: res.data.secure_url,
			}

			const userLocation = Cookies.get("client_location")
			const petUrl =
				userLocation === "milton" ? "/api/pets" : "/api/pets_cambridge"

			console.log(PetId)
			await axios.put(`${petUrl}/${PetId}`, newPetImg)
			fetchData()
			setLoadingAxiosReq(false)
		} catch (error) {
			console.log(error)
			setLoadingAxiosReq(false)
		}
	}

	return (
		<div className="pt-0 sm:pt-28 md:mt-24">
			{/* Mobile Menu */}
			<div
				className={`bg-black text-white text-2xl md:hidden p-2 w-full flex justify-start`}
			>
				<ul className="text-md mb-0 flex justify-between flex-row">
					<li
						className={`cursor-pointer ml-4 my-2 ${
							selectedPanel === "owner" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("owner")}
					>
						<span>
							<FaUser className="inline-block mr-3" />
						</span>
					</li>
					<li
						className={`cursor-pointer ml-4 my-2 ${
							selectedPanel === "vet" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("vet")}
					>
						<FaUserDoctor className="inline-block mr-3" />
					</li>
					<li
						className={`cursor-pointer ml-4 my-2 ${
							selectedPanel === "pet" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("pet")}
					>
						<FaPaw className="inline-block" />
					</li>
				</ul>
			</div>
			{/* SECONDARY Mobile Menu */}
			<div
				className={`bg-black text-2xl text-white md:hidden p-2 w-full flex justify-start`}
			>
				<ul className="text-md mb-0 flex justify-between flex-row">
					<li
						className={`cursor-pointer ml-6 my-2 ${
							selectedPanel === "grooming" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("grooming")}
					>
						<FaScissors className="inline-block" />
					</li>
					<li
						className={`cursor-pointer ml-6 my-2 ${
							selectedPanel === "daycare" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("daycare")}
					>
						<IoTennisball className="inline-block" />
					</li>
					<li
						className={`cursor-pointer ml-6 my-2 ${
							selectedPanel === "my_appointments" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("my_appointments")}
					>
						<FaCalendar className="inline-block" />
					</li>
					<li
						className={`cursor-pointer ml-6 my-2 ${
							selectedPanel === "purchase" && "text-pink-600"
						}`}
						onClick={() => handlePanelChange("purchase")}
					>
						<MdCardMembership className="inline-block" />
					</li>
				</ul>
			</div>

			<div className="flex md:mt-4 lg:mt-18 h-screen bg-gray-100">
				{/* Left Side - Menu Desktop */}
				<div
					className={`bg-black hidden md:block text-white p-4 w-1/7 border-r transition-all duration-300 ${
						isPanelOpen ? "" : ""
					}`}
				>
					<div
						className="flex items-center justify-between mb-4 cursor-pointer"
						onClick={togglePanel}
					>
						{/* <span className="text-xl font-bold">Actions Panel</span> */}
						{isPanelOpen ? (
							<span className="text-cyan-500 text-2xl">
								<FaAnglesLeft className="inline-block mr-2" />
								Menu
							</span>
						) : (
							<span className="text-gray-500 text-cyan-500 text-2xl">
								<FaAnglesRight className="inline-block mr-2" />
							</span>
						)}
					</div>
					<ul className="text-xl">
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "owner" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("owner")}
						>
							{isPanelOpen ? (
								<span>
									<FaUser className="inline-block mr-2" /> Owner
								</span>
							) : (
								<FaUser className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "vet" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("vet")}
						>
							{isPanelOpen ? (
								<span>
									<FaUserDoctor className="inline-block mr-2" /> Vet
								</span>
							) : (
								<FaUserDoctor className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "pet" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("pet")}
						>
							{isPanelOpen ? (
								<span>
									<FaPaw className="inline-block mr-2" /> Pet
								</span>
							) : (
								<FaPaw className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "grooming" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("grooming")}
						>
							{isPanelOpen ? (
								<span>
									<FaScissors className="inline-block mr-2" /> Book Grooming
								</span>
							) : (
								<FaScissors className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "daycare" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("daycare")}
						>
							{isPanelOpen ? (
								<span>
									<IoTennisball className="inline-block mr-2" /> Book Daycare
								</span>
							) : (
								<IoTennisball className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "my_appointments" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("my_appointments")}
						>
							{isPanelOpen ? (
								<span>
									<FaCalendar className="inline-block mr-2" /> My appointments
								</span>
							) : (
								<FaCalendar className="mr-2" />
							)}
						</li>
						<li
							className={`cursor-pointer my-4 ${
								selectedPanel === "purchase" && "text-yellow-200"
							}`}
							onClick={() => handlePanelChange("purchase")}
						>
							{isPanelOpen ? (
								<span>
									<MdCardMembership className="inline-block mr-2" /> Wallet
								</span>
							) : (
								<MdCardMembership className="mr-2" />
							)}
						</li>
					</ul>
				</div>

				{/* Right Side - Content */}
				<div className="flex-1 p-4">
					<div className="flex flex-row justify-between">
						<h4 className="text-2xl mb-4 capitalize">
							Welcome {data && `${data.firstName} ${data.lastName}`}
						</h4>
						<div className="flex h-full gap-2">
							<ReleaseStatus
								releaseType="Grooming"
								data={data}
								releaseOpenModal={releaseOpenModal}
								setReleaseOpenModal={setReleaseOpenModal}
							/>
							<ReleaseStatus
								releaseType="Daycare"
								data={data}
								setReleaseOpenModal={setReleaseOpenModal}
								releaseOpenModal={releaseOpenModal}
							/>
						</div>
					</div>

					{error && <p className="text-red-500">Error: {error}</p>}
					{selectedPanel === "owner" && data && (
						<div>
							{/* Display Owner Information */}
							<div className="flex flex-row justify-between">
								<div>
									<h5>Owner Information</h5>
								</div>
								<button
									onClick={() => setOpenModal(true)}
									className="bg-cyan-600 rounded-sm px-4 py-2 mb-2 text-white"
								>
									<CiEdit className="inline-block mr-3" />
									Edit
								</button>
							</div>
							<div className="card p-4">
								<p>Client Id: {data.id}</p>
								<p>Email: {data.email}</p>
								{data.primaryPhoneNumber && (
									<p>
										Primary Phone Number:
										{data.primaryPhoneNumber}
									</p>
								)}
								{data.cellphone && <p>Alternative Number: {data.cellphone}</p>}
								{data.workPhone && <p>Work Phone Number: {data.workPhone}</p>}

								<p>Address: {data.address}</p>
							</div>
						</div>
					)}
					{selectedPanel === "vet" &&
						data &&
						(data.Vet || data.VetCambridge) && (
							<div>
								<div className="flex flex-row justify-between">
									<div>
										<h5>Vet Information</h5>
									</div>
									<button
										onClick={() => setVetOpenModal(true)}
										className="bg-cyan-600 rounded-sm px-4 py-2 mb-2 text-white"
									>
										<CiEdit className="inline-block mr-3" />
										Edit
									</button>
								</div>
								<div className="card p-4">
									<p className="capitalize">
										Clinic Name:{" "}
										{data.Vet
											? data.Vet.vetClinicName
											: data.VetCambridge.vetClinicName}
									</p>
									<p className="capitalize">
										Vet Name:{" "}
										{data.Vet ? data.Vet.vetName : data.VetCambridge.vetName}
									</p>
									<p>
										Vet Phone:{" "}
										{data.Vet ? data.Vet.vetPhone : data.VetCambridge.vetPhone}
									</p>
									<p>
										Vet Clinic Address:{" "}
										{data.Vet
											? data.Vet.vetAddress
											: data.VetCambridge.vetAddress}
									</p>
								</div>
							</div>
						)}
					{selectedPanel === "pet" && data && (
						<div>
							{/* Display Pet Information */}
							<div>
								<div className="flex flex-row justify-between">
									<h5>Pet Information</h5>
								</div>
								<button
									onClick={() => setAddPetOpenModal(true)}
									className="bg-orange-600 right-4 rounded-sm px-4 py-2 mb-2 text-white w-48"
								>
									<i className="fas fa-plus"></i> Add a Pet{" "}
									<i className="fas fa-paw"></i>
								</button>
							</div>
							<div className="flex flex-wrap gap-2 flex-row justify-between">
								{data &&
									(data.Pets ? data.Pets : data.PetCambridges).map(
										(pet, index) =>
											!pet.deletedAt && (
												<div
													className="border shadow-lg p-4 flex-1"
													key={index}
												>
													<div className="flex flex-row justify-start">
														<div>
															<div className="w-[100px]">
																{pet.petImg ? (
																	<label className="cursor-pointer px-1 border">
																		<input
																			type="file"
																			accept="image/*"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(e, pet.id, "petImg")
																			}
																		/>
																		<img
																			className="w-full"
																			src={pet.petImg}
																			alt="Pet"
																		/>
																		<span className="mt-2 text-lg flex justify-center">
																			<MdAddPhotoAlternate />
																		</span>
																	</label>
																) : (
																	<label className="cursor-pointer border px-1">
																		<input
																			type="file"
																			accept="image/*"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(e, pet.id, "petImg")
																			}
																		/>
																		<img
																			className="w-full"
																			src={`${
																				pet.type.toLowerCase() === "dog"
																					? "/images/dog_black.png"
																					: "/images/cat_black.png"
																			}`}
																			alt="Dog and cat"
																		/>
																		<span className="mt-2 text-lg flex justify-center">
																			<MdAddPhotoAlternate />
																		</span>
																	</label>
																)}
															</div>
															<p className="capitalize">Pet Name: {pet.name}</p>
															<p className="capitalize">Breed: {pet.breed}</p>
															<p className="capitalize">Age: {pet.age}</p>
															{pet.color && (
																<p className="capitalize">Color: {pet.color}</p>
															)}
															<p className="capitalize">Gender: {pet.gender}</p>
															{pet.hasAllergies && (
																<>
																	<p>
																		Any allergies:{" "}
																		{pet.hasAllergies ? "Yes" : "No"}
																	</p>
																	<p className="capitalize">
																		Allergies Info: {pet.allergiesContent}
																	</p>
																</>
															)}
															{pet.bordatellaExpDate && (
																<p>
																	Bordetella Vaccine Exp Date:{" "}
																	{pet.bordatellaExpDate}
																</p>
															)}

															{pet.generalBehaviour && (
																<p className="capitalize">
																	General behaviour: {pet.generalBehaviour}
																</p>
															)}
														</div>
														<div className=" pl-4 ml-4">
															{pet.currentInjuries && (
																<p className="capitalize">
																	Injuries or medical concerns:{" "}
																	{pet.currentInjuries}
																</p>
															)}
															{pet.fixedDate && (
																<p>Neutered/Spayed date: {pet.fixedDate}</p>
															)}
															{pet.isFixed && (
																<p>
																	Is spayed/neutered:{" "}
																	{pet.isFixed ? "Yes" : "No"}
																</p>
															)}
															{pet.regularShotsExpireDate && (
																<p>
																	Regular shots exp date:{" "}
																	{pet.regularShotsExpireDate}
																</p>
															)}
															{pet.vaccineRecordImgUrl ? (
																<div className="p-2 flex border flex-col">
																	<button
																		onClick={() => {
																			setVaccineImg(pet.vaccineRecordImgUrl)
																			setVaccineImgType("Regular Shots Record")
																			setOpenVaccineModal(true)
																		}}
																		className="bg-cyan-600 rounded-sm text-white"
																	>
																		View Regular Shots
																	</button>
																	<label className="cursor-pointer">
																		<input
																			type="file"
																			accept="image/*, .pdf"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(
																					e,
																					pet.id,
																					"vaccineRecordImgUrl"
																				)
																			}
																		/>
																		<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
																			<span className="text-center">
																				Update Record Image
																			</span>
																			<span className="mt-2 text-lg flex justify-center">
																				<MdAddPhotoAlternate />
																			</span>
																		</span>
																	</label>
																</div>
															) : (
																<>
																	<span>Regular Vaccines</span>
																	<label className="cursor-pointer justify-center flex">
																		<input
																			type="file"
																			accept="image/*, .pdf"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(
																					e,
																					pet.id,
																					"vaccineRecordImgUrl"
																				)
																			}
																		/>
																		<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
																			<span className="text-center">
																				Update Record Image
																			</span>
																			<span className="mt-2 text-lg flex justify-center">
																				<MdAddPhotoAlternate />
																			</span>
																		</span>
																	</label>
																</>
															)}

															{pet.bordatellaImgUrl ? (
																<div className="p-2 mt-2 border flex flex-col">
																	<button
																		onClick={() => {
																			setVaccineImg(pet.bordatellaImgUrl)
																			setVaccineImgType(
																				"Bordatella Shots Record"
																			)
																			setOpenVaccineModal(true)
																		}}
																		className="bg-cyan-600 rounded-sm text-white"
																	>
																		View Bordetella Shots
																	</button>
																	<label className="cursor-pointer">
																		<input
																			type="file"
																			accept="image/*, .pdf"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(
																					e,
																					pet.id,
																					"bordatellaImgUrl"
																				)
																			}
																		/>
																		<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
																			<span className="text-center">
																				Update Record Image
																			</span>
																			<span className="mt-2 text-lg flex justify-center">
																				<MdAddPhotoAlternate />
																			</span>
																		</span>
																	</label>
																</div>
															) : (
																<>
																	<span>Bordetella Vaccine</span>
																	<label className="cursor-pointer justify-center flex">
																		<input
																			type="file"
																			accept="image/*, .pdf"
																			className="hidden"
																			onChange={(e) =>
																				handleImageUpload(
																					e,
																					pet.id,
																					"bordatellaImgUrl"
																				)
																			}
																		/>
																		<span className="mt-2 text-white flex justify-center bg-blue-300 w-full">
																			<span className="text-center">
																				Update Record Image
																			</span>
																			<span className="mt-2 text-lg flex justify-center">
																				<MdAddPhotoAlternate />
																			</span>
																		</span>
																	</label>
																</>
															)}
														</div>
													</div>
													<hr className="bg-yellow-300" />
													<span className="text-center text-yellow-400 text-xs">
														Please inform us if your pet information needs to be
														updated at{" "}
														<a href="mailto:info@amazingpetgrooming.ca">
															info@amazingpetgrooming.ca
														</a>
													</span>
												</div>
											)
									)}
							</div>
						</div>
					)}
					{selectedPanel === "my_appointments" && data && (
						<ClientAppointments
							clientPhone1={data.primaryPhoneNumber}
							clientPhone2={data.cellphone}
							clientPhone3={data.workPhone}
							clientEmail={data.email}
						/>
					)}

					{selectedPanel === "daycare" && data && (
						<div>
							{/* Display Pet Information */}
							<hr />
							<h5>Book a daycare spot</h5>
							<hr />
							<div className="flex flex-col gap-4">
								<DaycareBookingForm
									prefilledData={{
										firstName: data.firstName,
										lastName: data.lastName,
										email: data.email,
										phone: data.primaryPhoneNumber,
									}}
									appointmentType="daycare"
								/>
							</div>
							{/* <button
								onClick={() => setShowBookDaycareModal(true)}
								className="btn btn-info"
							>
								Initiate Daycare Booking
							</button> */}
						</div>
					)}
					{selectedPanel === "grooming" && data && (
						<div>
							{/* Display Pet Information  */}
							<hr />
							<h5>Book a grooming appointment</h5>
							<Booking
								clientPhone={data.primaryPhoneNumber}
								clientEmail={data.email}
							/>
						</div>
					)}
					{selectedPanel === "purchase" && data && (
						<Memberships
							clientLocation={clientCity}
							data={data && data}
							clientID={data && data.id}
						/>
					)}
				</div>
				{loadingAxiosReq && <LoadPage />}

				<UpdateClientModal
					modalToUpdateOwner={openModal}
					toggleModalToUpdateOwner={() => setOpenModal(!openModal)}
					firstName={data && data.firstName}
					lastName={data && data.lastName}
					primaryPhoneNumber={data && data.primaryPhoneNumber}
					cellphone={data && data.cellphone}
					workPhone={data && data.workPhone}
					address={data && data.address}
					email={data && data.email}
					locationSelected={clientCity}
					clientId={data && data.id}
					getClientInformation={fetchData}
					isPasswordTemporary={data && data.isPasswordTemporary}
					agreeMarketing={data && data.isSubscribed}
				/>
				{data && (data.Vet || data.VetCambridge) && (
					<UpdateVetModal
						vetId={data && (data.Vet ? data.Vet.id : data.VetCambridge.id)}
						modalToUpdateVet={vetOpenModal}
						toggleModalToUpdateVet={() => setVetOpenModal(!vetOpenModal)}
						vetName={
							data && (data.Vet ? data.Vet.vetName : data.VetCambridge.vetName)
						}
						vetPhone={
							data &&
							(data.Vet ? data.Vet.vetPhone : data.VetCambridge.vetPhone)
						}
						vetClinicName={
							data &&
							(data.Vet
								? data.Vet.vetClinicName
								: data.VetCambridge.vetClinicName)
						}
						vetAddress={
							data &&
							(data.Vet ? data.Vet.vetAddress : data.VetCambridge.vetAddress)
						}
						getVetInformation={fetchData}
						locationSelected={CITY}
						clientID={data && data.id}
					/>
				)}

				{/* Add Pet */}
				<Modal
					isOpen={addPetOpenModal}
					toggle={() => setAddPetOpenModal(!addPetOpenModal)}
				>
					<ModalBody>
						<PetDetails
							redInputHandler={redInputHandler}
							yesOrNoOptions={yesOrNoOptions}
							genderOptions={genderOptions}
							petTypeOptions={petTypeOptions}
							handlePetChange={handlePetChange}
							isPetReady={isPetButtonReady}
							formType={"add"}
							pet={petObj}
							clientId={data && data.id}
							fetchData={fetchData}
							setAddPetOpenModal={setAddPetOpenModal}
							mode={"daycare"}
							isClientDashboard={true}
						/>
					</ModalBody>
				</Modal>

				{/* Release Forms */}
				<Modal
					isOpen={releaseOpenModal}
					toggle={() => setReleaseOpenModal(!releaseOpenModal)}
				>
					<ModalBody>
						<h5 className="text-md text-center">
							Please fill out the following details
						</h5>
						<PetPendingDetails
							pets={data && (data.Pets || data.PetCambridges || [])}
							redInputHandler={redInputHandler}
							yesOrNoOptions={yesOrNoOptions}
							genderOptions={genderOptions}
							handlePetChange={handlePetChange}
							isPetReady={isPetButtonReady}
							clientId={data && data.id}
							fetchData={fetchData}
							setAddPetOpenModal={setAddPetOpenModal}
						/>
						{data && !data.signedDaycareRelease && (
							<ReleaseFormDaycare
								agree={signedReleaseForm}
								handleCheckboxChange={() =>
									setSignedReleaseForm(!signedReleaseForm)
								}
							/>
						)}
						{data && !data.signedGroomingRelease && (
							<ReleaseFormGrooming
								agree={signedReleaseForm}
								handleCheckboxChange={() =>
									setSignedReleaseForm(!signedReleaseForm)
								}
							/>
						)}
						{loadingAxiosReq ? (
							<LoadPage />
						) : (
							<button
								disabled={isPetButtonReady}
								onClick={updateOwnerRelease}
								className="w-full btn btn-info"
							>
								Submit
							</button>
						)}
					</ModalBody>
				</Modal>

				{/* Vaccine Records Image */}
				<Modal
					size="xl"
					isOpen={openVaccineModal}
					toggle={() => setOpenVaccineModal(!openVaccineModal)}
				>
					<ModalBody>
						<h4 className="capitalize flex justify-between">
							{vaccineImgType}{" "}
							<span
								className="cursor-pointer"
								onClick={() => setOpenVaccineModal(false)}
							>
								X
							</span>
						</h4>
						<hr />
						<img
							className="f-full m-auto"
							src={vaccineImg}
							alt="vaccine record"
						/>
					</ModalBody>
				</Modal>
			</div>
		</div>
	)
}

export default ClientDashboard
