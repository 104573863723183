import { useLayoutEffect, useRef } from "react"
import { CustomButton } from "../CustomButton"
import gsap, { Power4 } from "gsap"
import { Link } from "react-router-dom"
const ImageText = ({
	imageLeft = false,
	imageSrc,
	title,
	description,
	hasButton = false,
	onClickButton = () => {},
}) => {
	const container = useRef(null)

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({
				defaults: { ease: Power4.easeOut },
				scrollTrigger: {
					trigger: container.current,
					start: "top 90%",
				},
			})

			tl.fromTo(
				".imageTextContainer",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.7,
				}
			)
			// tl.fromTo(
			//   ".reviewCard",
			//   {
			//     y: 50,
			//     opacity: 0,
			//   },
			//   {
			//     y: 0,
			//     opacity: 1,
			//     duration: 0.7,
			//     stagger: 0.1,
			//   },
			//   "-=0.2"
			// );
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<div
			className="flex flex-col justify-between items-center w-full lg:flex-row gap-[50px]"
			ref={container}
		>
			<div
				className={`w-full lg:w-[50%] imageTextContainer ${
					imageLeft ? "lg:order-2" : "lg:order-1"
				}`}
			>
				<h3 className={`text-[30px] font-bold text-left`}>{title}</h3>
				<p
					dangerouslySetInnerHTML={{ __html: description }}
					className={`text-black text-left`}
				></p>
				{hasButton && (
					<Link
						to={"/schedule_daycare"}
						className="text-main hover:text-[#019DFF] hover:no-underline"
					>
						<CustomButton hasArrow>Book Daycare Now</CustomButton>
					</Link>
				)}
			</div>
			<div
				className={`w-full lg:w-[40%] flex justify-center items-center imageTextContainer ${
					imageLeft ? "lg:order-1" : "lg:order-2"
				}`}
			>
				<img className="object-cover w-[60%] lg:w-full" src={imageSrc} />
			</div>
		</div>
	)
}

export default ImageText
