import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react"
import TemporarySign from "../../components/TemporarySign"
// import Offers from "../../components/Offers";
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { Helmet } from "react-helmet"
import Wave from "react-wavify"
import { Services } from "../../components/Services"
import Divider from "../../components/Divider"
import { reviews } from "../../utils/constants"
import ReviewCard from "../../components/ReviewCard"
import SplitType from "split-type"
import { Back, Power4, gsap } from "gsap"
import { ScrollTrigger } from "gsap/all"
import axios from "axios"
gsap.registerPlugin(ScrollTrigger)

const Home = () => {
	const [modal, setModal] = useState(false)
	const [popupImgUrl, setPopupImgUrl] = useState("")
	const [popupVideoUrl, setPopupVideoUrl] = useState("")
	const [popupMediaType, setPopupMediaType] = useState("")
	const [popupLinkUrl, setPopupLinkUrl] = useState("")
	const { hasUserSeenPopUp, setHasUserSeenPopUp } =
		useContext(RegistrationContext)
	const container = useRef(null)
	const reviewsContainer = useRef(null)

	const fetchPopupContent = async () => {
		try {
			const response = await axios.get("/homepopup/setting")
			const filteredResponse = response.data.find((item) => item.isVisible)
			if (filteredResponse.mediaType === "video") {
				setPopupVideoUrl(filteredResponse.videoUrl)
			} else {
				setPopupImgUrl(filteredResponse.imgUrl)
			}
			setPopupLinkUrl(filteredResponse.linkUrl)
			setPopupMediaType(filteredResponse.mediaType)
			if (filteredResponse.isVisible && !hasUserSeenPopUp) {
				setModal(true)
			}
		} catch (error) {}
	}

	useEffect(() => {
		fetchPopupContent()
		localStorage.removeItem("USER_SAW_POPUP")
		if (hasUserSeenPopUp || localStorage.getItem("USER_SAW_POPUP")) {
			setModal(false)
		}
		// eslint-disable-next-line
	}, [])

	const closeModal = () => {
		setModal(false)
		setHasUserSeenPopUp(true)
		localStorage.setItem("USER_SAW_POPUP", true)
	}

	// Animations hero
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })
			const splitTitleParent = new SplitType("#homeMainText", {
				types: "lines",
				lineClass: "lineParent",
			})
			const splitTitle = new SplitType(".lineParent", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.1,
					delay: 0.7,
				}
			)
			tl.fromTo(
				".mainImage",
				{
					scale: 0.9,
					y: 100,
					opacity: 0,
				},
				{
					scale: 1,
					y: 0,
					opacity: 1,
					duration: 1,
					ease: Back.easeInOut.config(4),
				},
				"-=1.05"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	// Animations reviews
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({
				defaults: { ease: Power4.easeOut },
				scrollTrigger: {
					trigger: reviewsContainer.current,
					start: "top 70%",
				},
			})

			const splitTitleParent = new SplitType("#reviewsTitle", {
				types: "lines",
				lineClass: "lineReviewParent",
			})
			const splitTitle = new SplitType(".lineReviewParent", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.7,
					stagger: 0.1,
				}
			)
			tl.fromTo(
				".reviewCard",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.7,
					stagger: 0.1,
				},
				"-=0.2"
			)
		}, reviewsContainer)

		return () => ctx.revert()
	}, [])

	return (
		<div>
			<Helmet>
				<title>Home | Amazing Pet Grooming</title>
			</Helmet>
			<section
				className="w-full h-[100vh] md:h-[calc(100vh-90px)] flex items-start justify-center pt-[10%] lg:pt-0 overflow-hidden"
				id="home"
				ref={container}
			>
				<h2
					className="text-main pt-[34px] lg:pt-24 text-[20vw] md:text-[15vw] lg:text-[12vw] font-black text-center -z-[25]"
					id="homeMainText"
					style={{
						clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
					}}
				>
					SPOIL <span className="text-lightBlue">YOUR</span>
					<br /> BEST FRIEND.
				</h2>
				<img
					src="/images/hero-dog-cat.png"
					className="absolute bottom-0 lg:-bottom-[5%] w-full md:w-[60%] lg:w-[45%] -z-[20] mainImage"
				/>

				{/* Animated wave */}
				<div className="absolute w-full -bottom-[10%] lg:-bottom-[3%] -z-10">
					<Wave
						fill="#DDF2FF"
						options={{
							speed: 0.3,
							points: 3,
						}}
					/>
				</div>
			</section>
			<Services />
			<Divider />
			<section
				className="w-full h-full bg-lightBlue p-phone md:p-tablet lg:p-desktop flex flex-col gap-[50px]"
				ref={reviewsContainer}
			>
				<h4
					className="text-main text-[30px] md:text-[45px] font-black text-left"
					id="reviewsTitle"
				>
					We are rated 4.6<span> ⭐️ </span>on Google
				</h4>
				<div className="w-full h-full grid grid-cols-1 md:grid-cols-2 gap-[50px]">
					{reviews.map((review) => (
						<ReviewCard
							review={review}
							key={review.name}
							className="reviewCard"
						/>
					))}
				</div>
			</section>
			<TemporarySign
				popupImgUrl={popupImgUrl}
				popupVideoUrl={popupVideoUrl}
				close={closeModal}
				modal={modal}
				toggleModal={setModal}
				popupLinkUrl={popupLinkUrl}
				popupMediaType={popupMediaType}
			/>
		</div>
	)
}

export default Home
