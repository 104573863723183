import React, { useState, useEffect } from "react"
import "./App.scss"
import withClearCache from "./ClearCache"
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom"
import Toolbar from "./components/Toolbar"
import OldToolbar from "./components/OldToolbar"
import SideDrawer from "./components/SideDrawer/SideDrawer"
import Backdrop from "./components/Backdrop"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Gallery from "./pages/Gallery"
import Home from "./pages/Home"
import EmployeesProfile from "./pages/EmployeesProfile"
import Receptionist from "./pages/Receptionist"
import ReceptionistCambridge from "./pages/ReceptionistCambridge"
import PhotoManager from "./pages/PhotoManager"
import CustomerAddPage from "./pages/CustomerAddPage"
import CustomerAddPetPage from "./pages/CustomerAddPetPage"
import CustomerAddPageAllCities from "./pages/CustomerAddPageAllCities"
import CustomerAddPetPageCambridge from "./pages/CustomerAddPetPageAllCities"
import Commission from "./pages/Commission"
import Services from "./pages/Services"
import Daycare from "./pages/Daycare"
import ClientRegistration from "./pages/ClientRegistration"
import Footer from "./components/Footer"
import EditClients from "./components/EditClients"
import EditClientsCambridge from "./components/EditClientsCambridge"
import EditEmployees from "./components/EditEmployees"
import SignIn from "./components/SignIn/SignIn"
import ClientsLoginForm from "./components/SignIn/SignInClients"
import TermsAndConditions from "./pages/TermsAndConditions"
import NoMatch from "./pages/NoMatch/index"
import Staff from "./pages/Staff"
import Schedule from "./pages/Schedule"
import ScheduleDaycare from "./pages/ScheduleDaycare"
import { RegistrationContext } from "./contexts/registrationStatusContext"
import { Modal, ModalBody } from "reactstrap"
import ClientRegistrationModal from "./components/Modals/ClientRegistrationModal"
import TrialDayBookModal from "./components/Modals/TrialDayBookModal"
import DaycareBookModal from "./components/Modals/DaycareBookModal"
import "./index.css"
import Cookies from "js-cookie"
import ClientDashboard from "./pages/ClientDashboard"
import { jwtDecode } from "jwt-decode"
import SuccessReleaseForm from "./pages/SuccessReleaseForm"
import FailureReleaseForm from "./pages/FailureReleaseForm"
import Store from "./pages/Store"
import axios from "axios"
import Cart from "./components/Cart"
import ModalGeneralPurpose from "./components/Modals/ModalGeneralPurpose"
import CheckoutPage from "./pages/Checkout"
import Purchase from "./pages/Purchase"
import PurchaseDetails from "./pages/PurchaseDetails"
import FloatingCartIcon from "./components/FloatingCartIcon"
import useSessionChecker from "./hooks/useSessionChecker"

const ClearCacheComponent = withClearCache(MainApp)

function App() {
	return <ClearCacheComponent />
}

function MainApp() {
	const isAuthenticated = !!Cookies.get("authtoken")
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const [cart, setCart] = useState(() => {
		const savedCart = localStorage.getItem("cart")
		return savedCart ? JSON.parse(savedCart) : []
	})
	const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
	const [toolbarState, setToolbarState] = useState(true)
	const [isRegistrationInProcess, setIsRegistrationInProcess] = useState(false)
	const [hasUserSeenPopUp, setHasUserSeenPopUp] = useState(false)
	const [limitedAccessToCambridgeAdmin, setLimitedAccessToCambridgeAdmin] =
		useState(false)
	const [switchToOldToolbar, setSwitchToOldToolbar] = useState(false)
	const [showBookTrialDayModal, setShowBookTrialDayModal] = useState(false)
	const [cartModal, setCartModal] = useState(false)
	const [showBookDaycareModal, setShowBookDaycareModal] = useState(false)
	const [showLoginModal, setShowLoginModal] = useState(false)
	const [showRegisterDaycareModal, setShowRegisterDaycareModal] =
		useState(false)
	const [showRegisterGroomingModal, setShowRegisterGroomingModal] =
		useState(false)
	const [accDetailsForDaycare, setAccDetailsForDaycare] = useState(null)
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		petName: "",
		breed: "",
		agreeTerms: false,
	})
	const [commissions, setCommissions] = useState([])
	const accessString = localStorage.getItem("JOBTYPE")
	const username = localStorage.getItem("USERNAME")

	useSessionChecker(username)

	useEffect(() => {
		setHasUserSeenPopUp(true)
		if (accessString === "customer") {
			setToolbarState(false)
		}
	}, [])

	useEffect(() => {
		if (isMenuOpen) {
			setShowRegisterDaycareModal(false)
			setShowRegisterGroomingModal(false)
			setShowLoginModal(false)
		}
	}, [isMenuOpen])

	useEffect(() => {
		if (cart.length > 0) {
			localStorage.setItem("cart", JSON.stringify(cart))
		} else {
			localStorage.removeItem("cart")
		}
	}, [cart])

	useEffect(() => {
		const isOldToolbarActive = localStorage.getItem("OLD_TOOLBAR")
		localStorage.removeItem("FOUND_ACCOUNT")
		if (isOldToolbarActive != null) {
			setSwitchToOldToolbar(true)
		}
	}, [])

	useEffect(() => {
		const token = Cookies.get("authtoken")
		if (token) {
			const decodedToken = jwtDecode(token)
			if (decodedToken.exp * 1000 < Date.now()) {
				logoutHandler()
			}
		}
	}, [isAuthenticated])

	const logoutHandler = () => {
		Cookies.remove("authtoken")
		Cookies.remove("client_location")
		window.location.reload()
	}

	let backdrop

	const resetCartHandler = () => {
		setCart([])
	}

	const backdropClickHandler = () => {
		setSideDrawerOpen(!sideDrawerOpen)
	}

	const closeSideDrawerHandler = () => {
		setSideDrawerOpen(false)
	}

	const drawerToggleClickHandler = () => {
		setSideDrawerOpen(!sideDrawerOpen)
	}

	const updateCart = (updateFn) => {
		setCart((prevCart) => updateFn(prevCart))
	}

	const handleRemoveItem = (id) => {
		updateCart((prevCart) => prevCart.filter((item) => item.id !== id))
	}

	if (sideDrawerOpen) {
		backdrop = <Backdrop click={backdropClickHandler} />
	}

	const handleCheckout = async () => {
		try {
			const response = await axios.post("/api/store/checkout", {
				cart: cart.map((item) => ({
					stripePriceId: item.stripePriceId,
					quantity: item.quantity,
				})),
			})
			window.location.href = response.data.url
		} catch (error) {
			console.error("Failed to create checkout session", error)
		}
	}

	return (
		<div>
			<RegistrationContext.Provider
				value={{
					showPassword,
					setShowPassword,
					isRegistrationInProcess,
					setIsRegistrationInProcess,
					hasUserSeenPopUp,
					setHasUserSeenPopUp,
					closeSideDrawerHandler,
					limitedAccessToCambridgeAdmin,
					setLimitedAccessToCambridgeAdmin,
					switchToOldToolbar,
					setSwitchToOldToolbar,
					accDetailsForDaycare,
					setAccDetailsForDaycare,
					showLoginModal,
					setShowLoginModal,
					showRegisterDaycareModal,
					setShowRegisterDaycareModal,
					showRegisterGroomingModal,
					setShowRegisterGroomingModal,
					isAuthenticated,
					showBookTrialDayModal,
					setShowBookTrialDayModal,
					showBookDaycareModal,
					setShowBookDaycareModal,
					formData,
					setFormData,
					commissions,
					setCommissions,
					cart,
					setCart,
					setCartModal,
					isMenuOpen,
					setIsMenuOpen,
					updateCart,
					handleRemoveItem,
					resetCartHandler,
				}}
			>
				<Router>
					<header style={{ height: "100%" }}>
						{toolbarState &&
							(switchToOldToolbar ? (
								<>
									<OldToolbar
										drawerClickHandler={drawerToggleClickHandler}
										closeSideDrawerHandler={closeSideDrawerHandler}
									/>
									<SideDrawer show={sideDrawerOpen} />
									{backdrop}
								</>
							) : (
								// New toolbar
								<Toolbar
									drawerClickHandler={drawerToggleClickHandler}
									closeSideDrawerHandler={closeSideDrawerHandler}
								/>
							))}
					</header>
					<TrialDayBookModal />
					<DaycareBookModal />
					{showLoginModal && (
						<Modal
							modalClassName="mt-20"
							isOpen={showLoginModal}
							toggle={() => setShowLoginModal(!showLoginModal)}
						>
							<ModalBody>
								<ClientsLoginForm />
							</ModalBody>
						</Modal>
					)}
					<ClientRegistrationModal
						isModalOpen={showRegisterDaycareModal}
						toggleModal={setShowRegisterDaycareModal}
						modalType="daycare"
					/>
					<ClientRegistrationModal
						isModalOpen={showRegisterGroomingModal}
						toggleModal={setShowRegisterGroomingModal}
						modalType="grooming"
					/>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/clients/milton/:id" element={<EditClients />} />
						<Route
							path="/clients/cambridge/:id"
							element={<EditClientsCambridge />}
						/>
						<Route
							path="/client-dashboard"
							element={
								// <ClientDashboard />
								isAuthenticated ? (
									<ClientDashboard />
								) : (
									<Navigate to="/" replace={true} />
								)
							}
						/>

						<Route
							path="/auth/customer_milton/:regid"
							element={<CustomerAddPetPage />}
						/>
						<Route
							path="/auth/customer_cambridge/:regid"
							element={<CustomerAddPetPageCambridge />}
						/>
						<Route path="/auth/photo_manager" element={<PhotoManager />} />
						<Route path="/auth/reception" element={<Receptionist />} />
						<Route
							path="/auth/reception/cambridge"
							element={<ReceptionistCambridge />}
						/>
						<Route path="/auth/customer" element={<CustomerAddPage />} />
						<Route
							path="/clients_registration"
							element={<CustomerAddPageAllCities />}
						/>
						<Route
							path="/daycare_registration"
							element={<ClientRegistration mode="daycare" />}
						/>
						<Route
							path="/grooming_registration"
							element={<ClientRegistration mode="grooming" />}
						/>
						<Route path="/auth/commission" element={<Commission />} />
						<Route path="/auth/employees/:id" element={<EditEmployees />} />
						<Route path="/about" element={<About />} />
						<Route path="/services" element={<Services />} />
						<Route path="/daycare" element={<Daycare />} />
						<Route path="/gallery" element={<Gallery />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/schedule" element={<Schedule />} />
						<Route path="/schedule_daycare" element={<ScheduleDaycare />} />
						<Route path="/auth/admin_dashboard" element={<Staff />} />
						<Route
							path="/auth/employees_profile"
							element={<EmployeesProfile />}
						/>
						<Route
							path="/terms-and-conditions"
							element={<TermsAndConditions />}
						/>
						<Route path="/auth/login" element={<SignIn />} />
						<Route path="/auth/logout" element={<SignIn />} />
						<Route
							path="/verify/release_form/success"
							element={<SuccessReleaseForm />}
						/>
						<Route
							path="/verify/release_form/failure/:error"
							element={<FailureReleaseForm />}
						/>
						<Route path="/store" element={<Store />} />
						<Route path="/checkout" element={<CheckoutPage />} />
						<Route path="/purchase" element={<Purchase />} />
						<Route path="/purchase_details/:id" element={<PurchaseDetails />} />
						<Route element={NoMatch} />
					</Routes>

					<ModalGeneralPurpose
						size="lg"
						isModalOpen={cartModal}
						toggleModal={() => setCartModal(!cartModal)}
						modalProps={{
							cart: cart,
							onCheckout: handleCheckout,
							onUpdateCart: setCart,
						}}
						modalContent={Cart}
					/>
					{process.env.REACT_APP_TESTING_ADMIN_MODE !== "on" &&
						cart.length > 0 && (
							<div className="lg:hidden">
								<FloatingCartIcon position="fixed_custom" />
							</div>
						)}

					{!isAuthenticated && <Footer />}
				</Router>
			</RegistrationContext.Provider>
		</div>
	)
}

export default App
