import { useContext, useState } from "react"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import FaqItem from "../FaqItem"
import { Link } from "react-router-dom"

const FAQ = () => {
	const { setShowLoginModal, setShowBookTrialDayModal } =
		useContext(RegistrationContext)

	const [openedIndex, setOpenedIndex] = useState(null)

	const handleItemClick = (index) => {
		setOpenedIndex((prevIndex) => (prevIndex === index ? null : index))
	}

	return (
		<section className="w-full flex flex-col gap-[20px] p-phone md:p-tablet lg:px-desktop lg:py-tablet">
			<h2 className="mb-4 text-3xl font-semibold text-center text-main">
				Frequently Asked Questions
			</h2>

			<FaqItem
				question={"What are your opening hours?"}
				icon={"⏰"}
				isOpen={openedIndex === 0}
				onClick={() => {
					handleItemClick(0)
				}}
			>
				<p>
					Our opening hours differ between each of our Amazing Pet Grooming
					locations, but all our sites open early enough for you to drop your
					pup off before work, and stay open so you can pick them up on your way
					home!
				</p>
				<p>
					Our dog daycare centres are closed on Sundays and public holidays.
					Keep an eye on our social channels for pupdates!
				</p>
			</FaqItem>

			<FaqItem
				question={"How much does dog daycare cost?"}
				icon={"💰"}
				isOpen={openedIndex === 1}
				onClick={() => {
					handleItemClick(1)
				}}
			>
				<p>
					Dog daycare costs vary depending on duration and whether you use
					passes.
				</p>

				<p className="font-bold mt-2">
					Doggie Daycare Passes can be purchased online (from your client
					account) or in person.
				</p>

				<p className="mt-4">
					For savings and flexibility, you can purchase 5, 10, or 20 day passes
					at discounted rates. And for even better value, keep an eye out for
					our upcoming daycare memberships!
				</p>

				<div className="mt-6">
					<h4 className="text-lg font-semibold text-pink">Daycare Rates</h4>
					<div className="mt-4 space-y-6">
						<div>
							<h5 className="font-bold mb-1">⏱️ 2 Hours or Less</h5>
							<ul className="list-disc list-inside">
								<li>Pay-as-you-go: $12</li>
							</ul>
						</div>

						<div>
							<h5 className="font-bold mb-1">
								☀️ Half Day (Less than 5 Hours)
							</h5>
							<ul className="list-disc list-inside">
								<li>Pay-as-you-go: $20</li>
								<li>
									5 Day-Pass: $90{" "}
									<span className="text-pink font-semibold">
										($10.00 in savings)
									</span>
								</li>
								<li>
									10 Day-Pass: $160{" "}
									<span className="text-pink font-semibold">
										($40.00 in savings)
									</span>
								</li>
								<li>
									20 Day-Pass: $290{" "}
									<span className="text-pink font-semibold">
										($110.00 in savings)
									</span>
								</li>
							</ul>
						</div>

						<div>
							<h5 className="font-bold mb-1">🌞 Full Day (5+ Hours)</h5>
							<ul className="list-disc list-inside">
								<li>Pay-as-you-go: $32</li>
								<li>
									5 Day-Pass: $144{" "}
									<span className="text-pink font-semibold">
										($16.00 in savings)
									</span>
								</li>
								<li>
									10 Day-Pass: $280{" "}
									<span className="text-pink font-semibold">
										($40.00 in savings)
									</span>
								</li>
								<li>
									20 Day-Pass: $530{" "}
									<span className="text-pink font-semibold">
										($110.00 in savings)
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
					<img
						className="w-full"
						src="/images/passes/daycare_5_HD.png"
						alt="half day 5 day pass"
					/>
					<img
						className="w-full"
						src="/images/passes/daycare_10_HD.png"
						alt="half day 10 day pass"
					/>
					<img
						className="w-full"
						src="/images/passes/daycare_20_HD.png"
						alt="half day 20 day pass"
					/>
					<img
						className="w-full"
						src="/images/passes/5-day-FD.png"
						alt="full day 5 day pass"
					/>
					<img
						className="w-full"
						src="/images/passes/10-day-FD.png"
						alt="full day 10 day pass"
					/>
					<img
						className="w-full"
						src="/images/passes/20-day-FD.png"
						alt="full day 20 day pass"
					/>
				</div>

				<div className="mt-8 text-pink">
					<h5 className="font-semibold text-base mb-2">
						Memberships: Coming Soon...
					</h5>
					<p>
						A weekly membership for Amazing Pet Grooming Daycare offers great
						value and exclusive perks:
					</p>
					<ul className="pl-6 mt-2 list-disc">
						<li>One daycare visit per week</li>
						<li>Our lowest rate on extra days</li>
						<li>10% off dog wash and grooming services</li>
					</ul>
					<p className="mt-2">More info to come soon...</p>
				</div>
			</FaqItem>

			<FaqItem
				question={"Half day or full day? What's the difference?"}
				icon={"🌞"}
				isOpen={openedIndex === 2}
				onClick={() => {
					handleItemClick(2)
				}}
			>
				<p>
					At Amazing Pet Grooming Daycare, we also offer half-days of dog day
					care! A half-day visit is any visit up to 5 hours from the time you
					drop off your furry friend. If your dog will be staying over their
					scheduled half-day time, you'll be charged at the full day rate.
				</p>
			</FaqItem>

			<FaqItem
				question={"What vaccinations are required?"}
				icon={"💉"}
				isOpen={openedIndex === 3}
				onClick={() => {
					handleItemClick(3)
				}}
			>
				<p>
					In order to keep all members of the Amazing Pet Groomning Daycare
					family safe, puppies can attend 10 days after their second set of
					shots. <br />
					<b>Bordetella:</b> <br />
					Mandatory and no later than 48 hours before check-in to guarantee
					optimal effectiveness and safeguard against Kennel Cough. It's
					important to be aware that some veterinarians only administer this
					vaccine upon specific request. Our facility will need to see proof of
					current vaccination from pet owners (vaccination certificate). We do
					not accept titre tests.
					<br />
					<b>DPP (Distemper, Parvovirus and Parainfluenza)</b> 1 or 3-yr.
					vaccine is required. <br />
					<b>Rabies</b> 1 or 3-yr. vaccine is required.
				</p>
			</FaqItem>

			<FaqItem
				question={
					"Is it okay if my dog isn't spayed or neutered? Can it still participate?"
				}
				icon={"💉"}
				isOpen={openedIndex === 4}
				onClick={() => {
					handleItemClick(4)
				}}
			>
				<p>
					To ensure the well-being of other pups, Amazing Pet Grooming Daycare
					does not allow dogs over the age of 6 months that are not
					spayed/neutered, chemically castrated, or females in heat/in season.
					If intact males exhibit any undesirable behaviors, we may request you
					to keep them at home until they have been neutered.
				</p>
			</FaqItem>

			<FaqItem
				question={"How old does my dog need to be to come to daycare?"}
				icon={"🐶"}
				isOpen={openedIndex === 5}
				onClick={() => {
					handleItemClick(5)
				}}
			>
				<p>
					All dogs young and old are welcome at Amazing Pet Grooming Daycare!
					Puppies are permitted to attend 10 days post receiving the second set
					of shots.
				</p>
			</FaqItem>

			<FaqItem
				question={"What if my pet is shy or anxious?"}
				icon={"🥺"}
				isOpen={openedIndex === 6}
				onClick={() => {
					handleItemClick(6)
				}}
			>
				<p>
					Before your pooch joins us, they'll have completed a trial day to
					ensure the daycare environment is the right fit for them. Our trials
					are usually held on quieter days so as not to overwhelm your furball.
					<br />
					<br />
					Once your dog starts, we make sure to place your dog with a group of
					similar paw-sonalities. That way, they'll be able to play safely, and
					get the right balance of fun and chill they need and have the best
					day, every day. Ongoing sessions under the right supervision with
					other dogs are perfect for dogs with anxiety as they are slowly
					introduced to social situations with other dogs in a controlled and
					safe environment. It's a great confidence booster!
				</p>
			</FaqItem>

			<FaqItem
				question={"I'm a returning customer. How do I book my dog in?"}
				icon={"🫂"}
				isOpen={openedIndex === 8}
				onClick={() => {
					handleItemClick(8)
				}}
			>
				<p>
					Welcome back to the pack! You can ether book your dog in by clicking{" "}
					<Link to="/schedule_daycare">here</Link> or via our Customer Portal.{" "}
					<span
						onClick={() => setShowLoginModal(true)}
						className="cursor-pointer text-pink"
					>
						Login Here
					</span>
					<br />
					<br />
					Alternatively,{" "}
					<b>we also accept bookings via telephone or in person.</b>
					<br />
					<br />
					It is important that pet owners book ahead as otherwise you risk being
					turned away if the facility is at maximum capacity. Sometimes, our
					daycare fills up quickly, so book ahead to make sure you get your paws
					in the door!
				</p>
			</FaqItem>

			<FaqItem
				question={"What do I need to know about drop off and pick up?"}
				icon={"🚘"}
				isOpen={openedIndex === 9}
				onClick={() => {
					handleItemClick(9)
				}}
			>
				<div className="mb-4">
					<h4 className="mb-0 text-lg font-semibold">Drop off</h4>
					<p>
						While our activities are absolutely off the leash, we require all
						dogs to arrive at Amazing Pet Grooming Daycare on leash and wearing
						a well-fitted collar and/or walking harness. For the safety of both
						your own pup and other customers, your dog is to remain under your
						control at all times.
						<br />
						<br />
						On arrival, please wait until a staff member is ready to take your
						dog and do not allow them to roam the shop, jump on staff or
						approach other customers.
						<br />
						<br />
						We also know accidents happen, but please ensure you toilet your
						pooch before entering the shop.
					</p>
				</div>
				{/* Pick up */}
				<div>
					<h4 className="mb-0 text-lg font-semibold">Pick up</h4>
					<p>
						While we'd love to spend all hours of the day with your precious
						pups, the fun time must eventually come to an end! All dogs are
						required to be collected before the centre closing time. Opening
						hours for Amazing Pet Grooming dog daycare vary from location to
						location.
						<br />
						<br />
						<b>
							All our centres have a late fee of $10 per 5 minutes after our
							closing time. If you're running late, we will attempt to contact
							both you and your secondary contact. If you are unable to arrange
							the collection of your pet after 60 minutes, we will care for pup
							at the nearest boarding facility in town, or one of our team
							members will take your puppy with them, and you will need to
							arrange pickup from such location. If overnight boarding is
							required an extra $200 will be payable and you are required to
							arrange collection of your pet at your own expense.
						</b>
						<br />
						<br />
						If someone other than you will be collecting your dog from our
						centres, please inform our staff and make sure we have their contact
						details too!
					</p>
				</div>
			</FaqItem>

			<FaqItem
				question={"What if something happens to my pooch?"}
				icon={"👨🏻‍⚕️"}
				isOpen={openedIndex === 10}
				onClick={() => {
					handleItemClick(10)
				}}
			>
				<p>
					As pet parents ourselves, we understand the need for your pet to be
					looked after at all times and how stressful it can be to leave your
					furry friend with pet sitters. In the event of an emergency, you will
					be notified immediately for collection. Our employees will make every
					attempt to continue care where it is safe to do so and will transport
					your dog to our preferred local vet if required.
				</p>
			</FaqItem>
		</section>
	)
}

export default FAQ
