import { useContext, useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { Back, Power4, gsap } from "gsap"
import SplitType from "split-type"
import ImageText from "../../components/ImageText"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import FAQ from "../../components/Faq"
import { CustomButton } from "../../components/CustomButton"
import DaycareShowcase from "../../components/DaycareShowcase"
import { GiShoppingCart } from "react-icons/gi"
import { Link } from "react-router-dom"

const index = () => {
	const {
		setShowRegisterDaycareModal,
		setShowBookTrialDayModal,
		setShowLoginModal,
	} = useContext(RegistrationContext)
	const container = useRef(null)

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })

			//Title splitdaycareSecondaryText
			const splitTitleParent = new SplitType(".daycareMainText", {
				types: "lines",
				lineClass: "lineParentDaycareMainText",
			})
			const splitTitle = new SplitType(".lineParentDaycareMainText", {
				types: "lines",
			})
			const splitSecondaryTextParent = new SplitType(".daycareSecondaryText", {
				types: "lines",
				lineClass: "lineParentDaycareSecondaryText",
			})
			const splitSecondaryText = new SplitType(
				".lineParentDaycareSecondaryText",
				{
					types: "lines",
				}
			)

			tl.fromTo(
				[splitTitle.lines, splitSecondaryText.lines, ".daycareButton"],
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.2,
					stagger: 0.1,
				}
			)
			tl.fromTo(
				".daycareHeroImage",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
				},
				"-=0.9"
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<section className="relative flex w-full">
			<Helmet>
				<title>Daycare | Amazing Pet Grooming</title>
			</Helmet>
			<main className="flex flex-col w-full h-full bg-white" ref={container}>
				{/* {Hero} */}
				<section className="w-full h-[120vh] relative ">
					<div className="pt-[34px] md:pt-[110px] text-center flex flex-col items-center">
						<h1 className="text-main text-[30px] md:text-[45px] font-black daycareMainText">
							Doggie Daycare
						</h1>
						<h2 className="font-black text-black daycareSecondaryText">
							Let your dog: <br />
							<span className="font-medium">
								Play, socialize and burn off some energy.
							</span>
						</h2>

						<Link
							to={"/schedule_daycare"}
							className="text-main hover:text-[#019DFF] hover:no-underline"
						>
							<CustomButton hasArrow>Book Daycare Now</CustomButton>
						</Link>

						<CustomButton
							customColor={"slate"}
							className="max-w-fit mt-[15px] relative z-[20] daycareButton"
							onClick={() => setShowLoginModal(true)}
						>
							<GiShoppingCart className="inline mr-2" />
							Sign In to Buy Daycare passes
						</CustomButton>
					</div>
					<div
						className="absolute w-full h-[77vh] bottom-0 left-0 overflow-hidden daycareHeroImage"
						style={{
							backgroundImage: "url('/images/daycare-hero-img.png')",
							backgroundSize: "cover",
							backgroundPosition: "top",
							backgroundRepeat: "no-repeat",
						}}
					></div>
				</section>

				{/* {ImageText} */}
				<div className="w-full h-full p-phone md:p-tablet lg:p-desktop flex flex-col gap-[50px]">
					<ImageText
						title={"Socialization"}
						description="Doggie daycare offers a structured and supervised setting where dogs engage in playful activities, fostering not only physical exercise but also mental stimulation. This dynamic environment encourages dogs to explore their instincts and natural behaviors, such as chasing, fetching, and interacting with various toys. The diverse range of activities provided helps keep dogs mentally sharp and physically fit, contributing to their overall well-being.<br/>
Moreover, the presence of experienced staff in doggie daycare facilities ensures a safe and controlled environment. Trained professionals can identify and address any potential conflicts or issues that may arise during playtime, preventing the development of negative behaviors. This level of oversight is particularly beneficial for young puppies who are still learning appropriate social cues and boundaries.
In addition to the socialization benefits, doggie daycare can be a valuable resource for pet owners with busy schedules. <br/>Dogs often thrive on routine, and consistent attendance at daycare provides a structured schedule for them. This can be especially beneficial for dogs with separation anxiety or those who struggle with being alone for extended periods. Furthermore, the exposure to a variety of people and environments in doggie daycare can help dogs become more adaptable and less prone to stress in new situations. This adaptability is crucial for dogs that may encounter different environments, such as travel, vet visits, or encounters with new people."
						imageSrc="/images/daycare_socialization.png"
					/>
					<ImageText
						title={"Physical Exercise"}
						description="Regular play and activities at daycare play a pivotal role in ensuring your dog receives the essential physical exercise required to maintain optimal health. Engaging in interactive play not only helps your dog stay active but also aids in weight management, lowering the risk of obesity-related health problems. The cardiovascular benefits of such activities are noteworthy, promoting a healthy heart and overall well-being.<br/>
            Beyond the physical advantages, daily exercise is a key factor in fostering a happy and contented pet. Dogs that regularly participate in stimulating play sessions tend to exhibit more positive behavior and are less likely to develop behavioral problems. This is especially beneficial for high-energy breeds that thrive on physical activity, as it provides an outlet for their energy and reduces the likelihood of boredom-induced mischief.<br/>
            Moreover, the mental stimulation derived from regular exercise is instrumental in preventing boredom and destructive behaviors. Dogs, like humans, benefit from mental challenges, and engaging in playful activities at daycare helps keep their minds sharp and active. This mental stimulation is particularly important for intelligent breeds that require mental engagement to stay content and well-behaved. The holistic approach of incorporating both physical and mental exercise through daycare activities contributes to a well-rounded and fulfilled canine companion. Owners can witness the positive impact of regular play on their dog's demeanor, observing a more balanced and emotionally satisfied pet at home."
						imageSrc="/images/daycare_exercise.png"
						imageLeft
					/>
					<ImageText
						title={"How daycare works"}
						description="Doggy daycare at Amazing Pet Grooming Daycare kicks off bright and early at 7 am, as many of our beloved pet parents drop off their lively furballs on their way to work. Recognizing the unique paw-sonalities, preferences, and quirks of each dog, we initiate the morning by carefully curating playgroups that suit every pooch based on their size and play style.<br/>
            The real excitement unfolds thereafter. Your furry companion engages in delightful games and forms new friendships, all under the watchful eye of our dedicated Amazing crew. They possess the expertise to discern precisely when it's playtime for a spirited game of fetch and when it's time for a well-deserved rest, complete with a dedicated nap period! Striking this balance in dog daycare is crucial for ensuring that each dog has a fantastic time and leaves our facility as a content and joyous canine."
						imageSrc="/images/daycare_works.png"
					/>
					<ImageText
						title={"What does a day at doggie daycare looks like?"}
						description="Your pup's day at Amazing Pet Grooming Daycare is thoughtfully segmented into morning and afternoon play sessions. Interspersed between these lively activities is a designated quiet time (yes, quiet!) to allow them to recharge for more excitement. As a token of appreciation for good behavior, our team rewards them with delectable treats. Our ultimate objective is to ensure that every dog is not just content but genuinely happy.<br/>
            We understand that you want to be part of the joyous moments, and we've got you covered! Each day, we eagerly share some of the most delightful amazing moments with you and on our social media pages. Be sure to follow us to catch glimpses of the fun-filled experiences your furry friend is enjoying!
            "
						imageSrc="/images/daycare_lookslike.png"
						imageLeft
						hasButton
						onClickButton={() => setShowRegisterDaycareModal(true)}
					/>
				</div>

				<hr />
				<h3 className="text-center mt-4">Save more with Daycare Passes</h3>
				<div
					onClick={() => setShowLoginModal(true)}
					class="grid mx-auto cursor-pointer w-3/4 grid-cols-2 gap-4 p-4 md:grid-cols-3"
				>
					<img
						src="/images/passes/daycare_5_HD.png"
						alt="Image 1"
						class="w-full h-auto border"
					/>
					<img
						src="/images/passes/daycare_10_HD.png"
						alt="Image 2"
						class="w-full h-auto border"
					/>
					<img
						src="/images/passes/daycare_20_HD.png"
						alt="Image 3"
						class="w-full h-auto border"
					/>
					<img
						src="/images/passes/5-day-FD.png"
						alt="Image 4"
						class="w-full h-auto border"
					/>
					<img
						src="/images/passes/10-day-FD.png"
						alt="Image 5"
						class="w-full h-auto border"
					/>
					<img
						src="/images/passes/20-day-FD.png"
						alt="Image 6"
						class="w-full h-auto border"
					/>
				</div>
				<hr />

				<DaycareShowcase />
				<FAQ />
			</main>
		</section>
	)
}

export default index
