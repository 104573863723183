import { useState, useEffect, useRef, useContext } from "react"
import { Link } from "react-router-dom"
import FloatingCartIcon from "../FloatingCartIcon"
import { CustomButton } from "../CustomButton"
import BurgerMenu from "../BurgerMenu"
import gsap from "gsap"
import { MdFiberNew } from "react-icons/md"
import { GiShoppingBag } from "react-icons/gi"
import { RegistrationContext } from "../../contexts/registrationStatusContext"

const MobileMenu = ({
	isRegistrationInProcess,
	registrationStatusChecker,
	logoutHandler,
	setShowRegisterGroomingModal,
	setShowRegisterDaycareModal,
	isAuthenticated,
	setShowLoginModal,
	isMenuOpen,
	setIsMenuOpen,
	showRegisterDaycareModal,
	showLoginModal,
}) => {
	const { cart } = useContext(RegistrationContext)
	const [showMobileServicesDropdown, setShowMobileServicesDropdown] =
		useState(false)
	const [showMobileRegistrationDropdown, setShowMobileRegistrationDropdown] =
		useState(false)
	const [showMobileBookingDropdown, setShowMobileBookingDropdown] =
		useState(false)

	const menuRef = useRef(null)
	const linksRef = useRef([])
	const tl = useRef(null)

	useEffect(() => {
		if (showRegisterDaycareModal || showLoginModal) {
			setIsMenuOpen(false)
		}
	}, [showRegisterDaycareModal || showLoginModal])

	useEffect(() => {
		const menu = menuRef.current
		const links = linksRef.current

		if (menu && links.length) {
			tl.current = gsap
				.timeline({ paused: true })
				.to(menu, {
					opacity: 1,
					height: "100vh",
					duration: 0.8,
					ease: "expo.inOut",
				})
				.from(
					links,
					{
						opacity: 0,
						y: 20,
						stagger: 0.1,
						duration: 0.5,
						ease: "expo.inOut",
					},
					"-=0.5"
				)
		}
	}, [])

	const toggleMenu = () => {
		if (tl.current) {
			if (isMenuOpen) {
				tl.current.reverse()
			} else {
				tl.current.play()
			}
			setIsMenuOpen((prev) => !prev)
		}
	}

	return (
		<div className="lg:hidden">
			<Link to={!isRegistrationInProcess && "/"}>
				<img
					className="w-[70px] ml-2 mt-2"
					src="/images/newLogo.png"
					alt="Amazing pet grooming logo"
				/>
			</Link>
			{/* Burger Icon */}
			<BurgerMenu
				isMobileMenuOpen={isMenuOpen}
				setIsMobileMenuOpen={setIsMenuOpen}
				handleMobileMenuClicked={toggleMenu}
			/>

			<div
				ref={menuRef}
				className={`bg-lightBlue lg:hidden fixed top-0 left-0 w-full h-full px-[30px] py-[50px] menuContainer z-[1000] overflow-y-auto ${
					isMenuOpen ? "block" : "hidden"
				}`}
			>
				<ul
					className="flex flex-col items-center gap-[10px] text-main font-semibold w-full text-[6vw]"
					id="navBarUl"
				>
					{/* Logo */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="cursor-pointer itemMobileMenu"
						onClick={() => {
							toggleMenu()
							isRegistrationInProcess && registrationStatusChecker("")
						}}
					>
						<Link to={!isRegistrationInProcess && "/"} className="text-black">
							<img
								className="w-[100px]"
								src="/images/new-logo.png"
								alt="Logo"
							/>
						</Link>
					</li>

					{/* Cart Icon */}
					{process.env.REACT_APP_TESTING_ADMIN_MODE !== "on" && (
						<>
							{cart.length > 0 && (
								<li
									ref={(el) => linksRef.current.push(el)}
									className="cursor-pointer itemMobileMenu"
								>
									<FloatingCartIcon className="mb-2" position="relative" />
								</li>
							)}

							<li
								ref={(el) => linksRef.current.push(el)}
								className="cursor-pointer mt-2 itemMobileMenu"
								onClick={() => {
									toggleMenu()
									isRegistrationInProcess && registrationStatusChecker("store")
								}}
							>
								<Link
									to={!isRegistrationInProcess && "/store"}
									className="text-main"
								>
									<MdFiberNew
										size={30}
										color="orange"
										className="inline mr-1"
									/>
									Store <GiShoppingBag className="inline" />
								</Link>
							</li>
						</>
					)}

					{/* Services Dropdown */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="cursor-pointer itemMobileMenu"
						onClick={() => {
							setShowMobileServicesDropdown(!showMobileServicesDropdown)
							isRegistrationInProcess && registrationStatusChecker("services")
						}}
					>
						<span>Services</span>
					</li>
					{showMobileServicesDropdown && (
						<>
							<li
								ref={(el) => linksRef.current.push(el)}
								className="items-start ml-2 text-sm cursor-pointer"
								onClick={() => {
									toggleMenu()
									isRegistrationInProcess &&
										registrationStatusChecker("daycare")
									setShowMobileServicesDropdown(false)
								}}
							>
								<Link
									to={!isRegistrationInProcess && "/daycare"}
									className="text-main"
								>
									&#8226; Daycare
								</Link>
							</li>
							<li
								ref={(el) => linksRef.current.push(el)}
								className="items-start ml-2 text-sm cursor-pointer"
								onClick={() => {
									toggleMenu()
									isRegistrationInProcess &&
										registrationStatusChecker("grooming")
									setShowMobileServicesDropdown(false)
								}}
							>
								<Link
									to={!isRegistrationInProcess && "/services"}
									className="text-main"
								>
									&#8226; Grooming
								</Link>
							</li>
						</>
					)}

					{/* About Us */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="cursor-pointer itemMobileMenu"
						onClick={() => {
							toggleMenu()
							isRegistrationInProcess && registrationStatusChecker("about")
						}}
					>
						<Link
							to={!isRegistrationInProcess && "/about"}
							className="text-main"
						>
							About Us
						</Link>
					</li>

					{/* Gallery */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="cursor-pointer itemMobileMenu"
						onClick={() => {
							toggleMenu()
							isRegistrationInProcess && registrationStatusChecker("gallery")
						}}
					>
						<Link
							to={!isRegistrationInProcess && "/gallery"}
							className="text-main"
						>
							Gallery
						</Link>
					</li>

					{/* Contact */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="cursor-pointer itemMobileMenu"
						onClick={() => {
							toggleMenu()
							isRegistrationInProcess && registrationStatusChecker("contact")
						}}
					>
						<Link
							to={!isRegistrationInProcess && "/contact"}
							className="text-main"
						>
							Contact
						</Link>
					</li>

					{/* Authentication */}
					{isAuthenticated ? (
						<>
							<li
								ref={(el) => linksRef.current.push(el)}
								className="p-2 bg-blue-100 rounded-sm"
							>
								<Link
									onClick={toggleMenu}
									to={!isRegistrationInProcess && "/client-dashboard"}
									className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
								>
									Profile
								</Link>
							</li>
							<button
								className="px-4 py-2 font-bold text-black bg-yellow-300 rounded-md hover:bg-yellow-400"
								onClick={() => {
									logoutHandler()
									toggleMenu()
								}}
							>
								Logout
							</button>
						</>
					) : (
						<>
							{/* Login */}
							<li
								ref={(el) => linksRef.current.push(el)}
								className="cursor-pointer itemMobileMenu"
								onClick={() => {
									isRegistrationInProcess &&
										registrationStatusChecker("contact")
									setShowLoginModal(true)
								}}
							>
								<div className="text-main hover:text-[#019DFF] hover:no-underline">
									Login
								</div>
							</li>

							{/* Register */}
							<li
								ref={(el) => linksRef.current.push(el)}
								className="cursor-pointer itemMobileMenu"
								onClick={() => {
									setShowMobileRegistrationDropdown(
										!showMobileRegistrationDropdown
									)
								}}
							>
								<span>Register</span>
							</li>
							{showMobileRegistrationDropdown && (
								<>
									<span
										ref={(el) => linksRef.current.push(el)}
										className="text-sm text-main"
										onClick={() => {
											setShowRegisterGroomingModal(true)
											setShowMobileRegistrationDropdown(false)
											toggleMenu()
										}}
									>
										&#8226; Grooming
									</span>
									<span
										ref={(el) => linksRef.current.push(el)}
										className="text-sm text-main"
										onClick={() => {
											setShowRegisterDaycareModal(true)
											setShowMobileRegistrationDropdown(false)
											toggleMenu()
										}}
									>
										&#8226; Daycare
									</span>
								</>
							)}
						</>
					)}

					{/* Booking */}
					<li
						ref={(el) => linksRef.current.push(el)}
						className="mt-2 cursor-pointer itemMobileMenu"
						onClick={() => {
							setShowMobileBookingDropdown(!showMobileBookingDropdown)
						}}
					>
						<span>Booking</span>
					</li>
					{showMobileBookingDropdown && (
						<>
							<span
								ref={(el) => linksRef.current.push(el)}
								className="text-sm text-main"
								onClick={() => {
									toggleMenu()
									isRegistrationInProcess &&
										registrationStatusChecker("schedule")
								}}
							>
								<Link to={!isRegistrationInProcess && "/schedule"}>
									&#8226; Grooming
								</Link>
							</span>
							<span
								ref={(el) => linksRef.current.push(el)}
								className="text-sm text-main"
								onClick={() => {
									toggleMenu()
									isRegistrationInProcess &&
										registrationStatusChecker("schedule_daycare")
								}}
							>
								<Link to={!isRegistrationInProcess && "/schedule_daycare"}>
									&#8226; Daycare
								</Link>
							</span>
						</>
					)}
				</ul>
			</div>
		</div>
	)
}

export default MobileMenu
