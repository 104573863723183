import { useState, useEffect, useRef } from "react"
import MediaUploader from "../MediaUploader"
import axios from "axios"
import AvailableDesignsPopup from "../AvailableDesignsPopup"

export default function Index() {
	const fileInputRef = useRef(null)
	const [uploading, setUploading] = useState(false)
	const [image, setImage] = useState(null)
	const [video, setVideo] = useState(null)
	const [linkUrl, setLinkUrl] = useState(null)
	const [visibility, setVisibility] = useState("public")
	const [media, setMedia] = useState([])
	const [preview, setPreview] = useState(null)
	const accessString = localStorage.getItem("JWT")
	const [settings, setSettings] = useState({
		title: "",
		description: "",
		imageUrl: "",
		videoUrl: "",
		isVisible: false,
		mediaType: "image",
	})

	useEffect(() => {
		fetchMedia()
	}, [])

	const fetchMedia = async () => {
		setPreview(null)
		try {
			const response = await axios.get("/homepopup/setting")
			setMedia(response.data)
		} catch (error) {
			console.error("Error fetching images:", error)
		}
	}

	const clearFileSelection = () => {
		setPreview(null)
		if (settings.mediaType === "image") {
			setImage(null)
		} else {
			setVideo(null)
		}
		if (fileInputRef.current) {
			fileInputRef.current.value = ""
		}
	}

	const handleUploadAndSave = async () => {
		if (!image && !video) {
			alert("Please select an image or video first.")
			return
		}

		setUploading(true)
		const formData = new FormData()
		if (settings.mediaType === "image") {
			formData.append("file", image)
			formData.append("upload_preset", "amazing")
		} else {
			formData.append("file", video)
			formData.append("upload_preset", "amazing_video")
		}

		try {
			const uploadResponse = await axios.post(
				`https://api.cloudinary.com/v1_1/amazing-pet-grooming/${settings.mediaType}/upload`,
				formData
			)
			const uploadedUrl = uploadResponse.data.secure_url
			await axios.post(
				"/homepopup/new",
				{
					[settings.mediaType === "image" ? "imgUrl" : "videoUrl"]: uploadedUrl,
					mediaType: settings.mediaType,
					isVisible: visibility === "public" ? true : false,
					linkUrl,
				},
				{
					headers: { jwt: `${accessString}` },
				}
			)

			fetchMedia()
			setSettings({
				title: "",
				description: "",
				imageUrl: "",
				videoUrl: "",
				isVisible: false,
				mediaType: "image",
			})
			clearFileSelection()
		} catch (error) {
			console.error("Error uploading or saving:", error)
			alert("Failed to upload or save.")
		} finally {
			setUploading(false)
		}
	}

	return (
		<div className="p-4 bg-gray-50 min-h-screen">
			<div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
				<AvailableDesignsPopup fetchMedia={fetchMedia} media={media} />
				<hr />
				{uploading ? (
					<div className="flex justify-center items-center">
						<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
					</div>
				) : (
					<div>
						<div className="mb-4">
							<label className="block mb-2">Media Type</label>
							<select
								value={settings.mediaType}
								onChange={(e) =>
									setSettings({ ...settings, mediaType: e.target.value })
								}
								className="w-full p-2 border rounded"
							>
								<option value="image">Image</option>
								<option value="video">Video</option>
							</select>
						</div>

						{settings.mediaType === "image" ? (
							<div className="mb-4">
								<label className="block mb-2">Upload Image</label>
								<MediaUploader
									fileInputRef={fileInputRef}
									setImage={setImage}
									setVideo={setVideo}
									preview={preview}
									setPreview={setPreview}
									type="image"
									clearFileSelection={clearFileSelection}
								/>
								{settings.imageUrl && (
									<img
										src={settings.imageUrl}
										alt="Preview"
										className="mt-2 max-w-xs"
									/>
								)}
							</div>
						) : (
							<div className="mb-4">
								<label className="block mb-2">Upload Video</label>
								<MediaUploader
									fileInputRef={fileInputRef}
									setImage={setImage}
									setVideo={setVideo}
									preview={preview}
									setPreview={setPreview}
									type="video"
									clearFileSelection={clearFileSelection}
								/>
								{settings.videoUrl && (
									<video controls className="mt-2 max-w-xs">
										<source src={settings.videoUrl} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								)}
							</div>
						)}

						<div className="text-center w-full">
							<input
								onChange={(e) => setLinkUrl(e.target.value)}
								className="border rounded-md p-1 w-3/4"
								type="text"
								placeholder="Path Url, eg: store, services, daycare, gallery, "
							/>
						</div>

						<div className="mt-4">
							<h2 className="text-lg font-semibold mb-2">
								Set Popup Visibility
							</h2>
							<div className="flex items-center space-x-4">
								<label className="flex items-center space-x-2">
									<input
										type="radio"
										value="public"
										name="visibility"
										checked={visibility === "public"}
										onChange={(e) => setVisibility(e.target.value)}
										className="text-indigo-600 focus:ring-indigo-500"
									/>
									<span>Public</span>
								</label>
								<label className="flex items-center space-x-2">
									<input
										type="radio"
										value="private"
										name="visibility"
										checked={visibility === "private"}
										onChange={(e) => setVisibility(e.target.value)}
										className="text-indigo-600 focus:ring-indigo-500"
									/>
									<span>Private</span>
								</label>
							</div>
						</div>
					</div>
				)}

				<button
					disabled={uploading}
					onClick={handleUploadAndSave}
					className="bg-blue-500 text-white px-4 py-2 rounded-md"
				>
					Upload and Save
				</button>
			</div>
		</div>
	)
}
