import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import DaycareBookingForm from "../../components/DaycareBookingForm"
import "./style.scss"

const ScheduleDaycare = () => {
	// Effect to scroll to top when component mounts
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<section className="schedule-container mt-20">
			<Helmet>
				<title>Daycare Booking | Amazing Pet Grooming</title>
				<meta
					name="description"
					content="Book your pet's daycare session online"
				/>
			</Helmet>

			<div className="booking-selection-container">
				<h1 className="text-center text-3xl font-bold mb-8">Daycare Booking</h1>
				<DaycareBookingForm appointmentType="daycare" />
			</div>
		</section>
	)
}

export default ScheduleDaycare
