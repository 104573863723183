import { useState, useEffect } from "react"

const MediaUploader = ({
	setImage,
	setVideo,
	type,
	initialUrl = null,
	clearFileSelection,
	fileInputRef,
	preview,
	setPreview,
}) => {
	const [error, setError] = useState(null)

	useEffect(() => {
		setPreview(initialUrl)
	}, [initialUrl])

	const handleFileSelect = (e) => {
		const file = e.target.files[0]
		if (!file) return

		if (type === "image" && !file.type.startsWith("image/")) {
			setError("Please select an image file")
			return
		}
		if (type === "video" && !file.type.startsWith("video/")) {
			setError("Please select a video file")
			return
		}

		const previewUrl = URL.createObjectURL(file)
		setPreview(previewUrl)
		setError(null)

		if (type === "image") {
			setImage(file)
		} else {
			setVideo(file)
		}
	}

	return (
		<div className="space-y-4">
			<div className="flex items-center space-x-4">
				<input
					ref={fileInputRef}
					type="file"
					onChange={handleFileSelect}
					accept={type === "video" ? "video/*" : "image/*"}
					className="w-full p-2 border rounded"
				/>
				{preview && (
					<button
						onClick={clearFileSelection}
						className="text-red-500 hover:text-red-700"
					>
						Clear
					</button>
				)}
			</div>

			{error && <div className="text-red-500 text-sm">{error}</div>}

			{preview && (
				<div className="mt-4 border rounded p-2">
					{type === "image" ? (
						<img
							src={preview}
							alt="Preview"
							className="max-w-full h-auto max-h-64 object-contain"
						/>
					) : (
						<video
							src={preview}
							controls
							className="max-w-full h-auto max-h-64"
						>
							Your browser does not support the video tag.
						</video>
					)}
				</div>
			)}
		</div>
	)
}

export default MediaUploader
