const DAYCARE_TRIAL_TIME_MORNING = "Anytime between 7:00am and 1:30pm"
const DAYCARE_TRIAL_TIME_AFTERNOON = "Anytime between 1:30pm and 6:00pm"

const morningStartTimes = [
	"7:00 AM",
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
]

const afternoonStartTimes = [
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
]

const allEndTimes = [
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"1:00 PM",
	"1:30 PM",
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
	"5:30 PM",
	"6:00 PM",
	"6:30 PM",
	"7:00 PM",
]

const services = [
	{
		name: "Spa",
		description:
			"Amazing Pet Grooming has packages to suit different needs and budgets. From the Basic Bath and Brush package, to the Premium Full Service Groom, Amazing Pet Grooming provides a variety of options for your pet. Take your pets to the professionals, after all… they deserve it.",
		icon: "🛁",
	},
	{
		name: "Haircut",
		description:
			"Haircuts and Styling are frequently needed based on the breed. Our professional dog/cat groomers have extensive experience on all styles and breeds. We will custom design your dog's/cat's haircut based on your taste and requirements.",
		icon: "✂️",
	},
	{
		name: "Drying and Brushing",
		description:
			"Drying and brushing is one of the most important steps in grooming your dog. By removing all matted and loose fur before the haircut, your dog and cat will come out looking completely fantastic.",
		icon: "🧼",
	},
	{
		name: "Ear cleaning",
		description:
			"Dog's and cat's ears are very sensitive and susceptible to infection, excessive wax buildup and parasites. A gentle cleaning with proper products will eliminate the majority of those problems. By removing the fur inside their ears or plucking them, it will help maintain their ears in a healthy condition.",
		icon: "🧽",
	},
	{
		name: "Bathing",
		description:
			"We bathe your dog with mild shampoos that are safe to use with Front-line and Advantage and other spot-on products. We express the anal gland before bathing (upon request). Regular bathing keeps your dog's skin healthy, and his/her coat clean and shiny.",
		icon: "🚿",
	},
	{
		name: "Nail Trimming",
		description:
			"Nail trimming is an essential part of dog/cat grooming, and trimmed nails are one clear sign of your dog’s good health and hygiene. Dogs and Cats need to have their nails trimmed at least every 4-6 weeks in order to maintain their quick nice and short; in addition, grinding their nails will reduce the sharpness after trimming them.",
		icon: "🐾",
	},
	{
		name: "Teeth Cleaning",
		description:
			"Teeth Cleaning on a regular basis will reduce tartar and help prevent periodontal disease. Brushing is important because it cleans away the plaque that leads to bad breath or more serious problems such as decayed teeth or gum disease.",
		icon: "🦷",
	},
	{
		name: "Dematting",
		description:
			"Dematting is a very important task in grooming. Hairs stuck together and shaggy hairs do not only provide dogs and cats an ill-kempt appearance, but they are also the hiding place for different kinds of parasites, and it may be also the hotbed of diseases. De-Tangling services can be provided when coat has been maintained properly by brushing it out regularly.",
		icon: "🪮",
	},
	{
		name: "Cat Services",
		description:
			"Only the most experienced groomers are able to handle these precious felines, and we are really proud to say that we have one of the best Cat Groomers in Ontario. From the basic nail trim to the premium package that includes a bath and a haircut, cat services are offered at both locations. Lion cuts, dragon cuts, you name it, and we will make it a reality.",
		icon: "🐈",
	},
	// Agrega más objetos de servicios según tus necesidades
]

const reviews = [
	{
		name: "Aishwarya Rangnekar",
		image: "/images/review4.png",
		review:
			"Very friendly, can tell they really care about the pets that come through, and they do a great job. Also, having a daycare along with the grooming services is wonderful! Great place to take your lil family members to :)",
	},
	{
		name: "Photoula Christopher",
		image: "/images/review3.png",
		review:
			"Amazing staff, so kind and caring. Our dog Apollo loves coming here, he gets so excited as soon as he walks in. One day of play= 2 days of recovery. I wish we lived closer so we could bring him here more often. I highly recommend the Doggy Daycare.",
	},
]

const locations = [
	{
		name: "Milton",
		phoneNumbers: "905 878-9009 🐾 905 878-5557",
		address: "264 Bronte St S, Unit 15 - Milton, ON - L9T 5A3",
		map: {
			title: "Amazing Pet Grooming - Cambridge",
			link: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11571.849867984401!2d-79.8709564!3d43.5239754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1616d6c9e9e1545!2sAmazing%20Pet%20Grooming!5e0!3m2!1sen!2sca!4v1647126390955!5m2!1sen!2sca",
		},
	},
	{
		name: "Cambridge",
		phoneNumbers: "647 368-7220",
		address: "383 Elgin St N Unit #1 - Cambridge, ON - N1R 8C1",
		map: {
			title: "Amazing Pet Grooming - Cambridge",
			link: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11599.61280696455!2d-80.305793!3d43.379048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36e44a611a5e047e!2sAmazing%20Pet%20Grooming!5e0!3m2!1sen!2sca!4v1647126027541!5m2!1sen!2sca",
		},
	},
]

const teamMembers = {
	paola: {
		name: "Paola",
		image: "/images/paula-main.png",
		color: "#DF2C7C",
		position: "groomer",
		description:
			"Paula, (or 'The Cat Whisperer', as some people call her), has been in the pet grooming industry for over 25 years. <i>Graduated from the Nash Pet Grooming Academy in Kentucky</i>, she has dedicated most of her life to work with dogs and cats. She's passionate about nature and animal care. Her vast experience with all kind of dogs and cats is something we have the privilege to share with our customers.",
	},
	diana: {
		name: "Diana",
		image: "/images/diana-main.png",
		color: "#3977EC",
		position: "groomer",
		description:
			"Diana has been working around animals for over 20 years. <i>She holds a Veterinarian diploma from a Colombian University.</i> Clients have told her that she has a special ability for working with dogs that other groomers turn away. She takes great pride in her work and is very much a Quality over Quantity type of groomer. She keeps up to date on the latest grooming trends, equipment, and products by attending trade shows and seminars.",
	},
	// mercedes: {
	// 	name: "Mercedes",
	// 	image: "/images/mercedes-main.png",
	// 	color: "#FFF8DC",
	// 	position: "groomer",
	// 	description:
	// 		"Meet Mercedes! With over three decades of experience working with animals, Mercedes provides not only experience but also an unparalleled level of dedication and passion for the well-being of all creatures great and small. Her journey began as a young volunteer at a local animal shelter, where she quickly discovered her innate connection with animals and her desire to make a difference in their lives. Beyond her professional accomplishments, Mercedes's true strength lies in her ability to forge meaningful connections with animals. She believes in fostering a harmonious relationship between people and animals, advocating for empathy, respect, and understanding in all interactions.",
	// },

	peppa: {
		name: "Peppa",
		image: "/images/peppa-main.png",
		color: "#22E34D",
		position: "groomer",
		description:
			"Peppa has spent over two decades immersed in the world of animal care, with her journey beginning in the vibrant landscapes of Chile. Having honed her skills and knowledge through hands-on experience, Peppa brings a unique perspective shaped by the rich biodiversity and distinct cultural influences of her homeland. She is no stranger to the challenges and joys of working with a diverse array of dog breeds. Peppa's passion for her craft is palpable, and her dedication to providing top-notch care is evident in every interaction. She remains deeply connected to her Chilean roots, drawing inspiration from its natural wonders and drawing upon traditional methods alongside modern techniques. Peppa's commitment to excellence knows no bounds, making her a trusted and invaluable asset to the animal care community, both in Chile and beyond.",
	},
	baths: {
		name: "Paola E.",
		image: "/images/paola_bather-main.png",
		color: "#db767a",
		position: "bather",
		description:
			"Paola E! Hailing from the beautiful landscapes of Colombia, Paola is a dedicated dog bather with a deep love for animals. With years of hands-on experience, she brings expertise and care to every pet she meets. Paola's approach is rooted in kindness and patience, ensuring each dog feels at ease during their bath. She knows that a clean coat is just as important as a happy heart, and her gentle touch creates a relaxing experience for every furry friend. Passionate about pet care, Paola takes pride in helping dogs look and feel their best while building trust with their owners. Whether it's a soothing bath or a friendly smile, Paola's dedication shines through in everything she does. Trust Paola to give your beloved pet the tender care they deserve!",
	},
	maddy: {
		name: "Maddy",
		image: "/images/maddy-main.png",
		color: "#fc8dc7",
		position: "receptionist",
		description:
			"Meet Maddy! As our friendly and organized Front Desk Specialist in our Milton location, Maddy is one of the welcoming faces and warm voice that greets every client. With a lifelong love for animals, she’s passionate about making every visit smooth and enjoyable for both pets and their owners.When she’s not providing top-notch customer service, Maddy enjoys reading, writing, and spending time with her own three beloved dogs. A full-time student pursuing her bachelor’s in Creative Writing, Maddy brings creativity and care to everything she does. Her love for pets and people alike makes her an invaluable part of our team!",
	},
	andreia: {
		name: "Andreia",
		image: "/images/andreia-main.png",
		color: "#fc8dc7",
		position: "receptionist",

		description:
			"Born in Portugal and a proud resident for the past 30 years, Andreia brings a warm, welcoming presence to Amazing Pet Grooming as our front desk receptionist. Her lifelong love for animals shines through in everything she does, from greeting clients with a smile to making every pet feel at home. She’s currently a devoted dog mom to Raven, her beloved French bulldog. With a background in fast-paced, customer-focused roles, Andreia thrives under pressure and brings excellent communication and organizational skills to our team. Her ability to multitask while staying attentive and kind makes her an essential part of the experience we offer. Outside of work, Andreia is passionate about fashion and makeup, areas in which she holds a diploma, and she treasures quality time with her family. Whether she’s coordinating appointments or offering a friendly word to a nervous pet parent, Andreia’s dedication and warmth help make Amazing Pet Grooming feel like home for both pets and people.",
	},
	mateo: {
		name: "Mateo",
		image: "/images/mateo-main.png",
		color: "#5594ab",
		position: "receptionist",
		description:
			"Mateo has always had a deep and abiding love for animals, which makes his role as a front desk associate at Amazing the ideal job for him. His passion for creating meaningful connections extends beyond just his work with pets—he genuinely enjoys engaging with clients, whether they’re furry friends or their human companions. Mateo has a natural talent for making everyone feel comfortable and valued, ensuring a warm, welcoming, and friendly atmosphere every time someone steps through the door. His ability to juggle responsibilities with a smile and his unwavering enthusiasm for helping others have made him a cornerstone of the team. When Mateo isn’t busy greeting guests and ensuring everything runs smoothly, you can often find him honing his computer programming expertise or working on creative projects that showcase his awesome graphic design skills.",
	},
	// gina: {
	// 	name: "Gina",
	// 	image: "/images/gina-main.png",
	// 	color: "#f5d142",
	// 	position: "receptionist",
	// 	description:
	// 		"Meet Gina, one of our amazing Front Desk Specialists at our Milton location. Originally from Colombia, Gina brings warmth, enthusiasm, and a love for animals to everything she does. Growing up, she was surrounded by pets and developed a lifelong passion for caring for them. Now, as a proud mom of four children and a dog, Gina understands the special bond between animals and their families. Her welcoming nature and dedication make every visit smooth and enjoyable for both pets and their humans. Gina is a vital part of our team, and we’re lucky to have her!",
	// },
	jorge: {
		name: "Jorge",
		image: "/images/jorge-main.png",
		color: "#bdbab3",
		position: "receptionist",
		description:
			"Jorge, originally from the beautiful landscapes of Peru, has always had a deep connection with animals, especially dogs. Growing up, his home was filled with the joy and energy of multiple dogs, each of whom taught him the values of loyalty, care, and unconditional love. Now living in Milton, Ontario, Jorge brings this lifelong passion to his work where he dedicates himself to providing a safe and happy space for dogs of all breeds and personalities.Jorge’s approach to dog care is rooted in his personal experience of living alongside many dogs throughout his life. He understands the unique needs of each pup. His warm and attentive nature ensures that every dog feels like part of the family, while their owners have peace of mind knowing their pets are in loving hands.",
	},
	baths_cambridge: {
		name: "bath_Cambridge",
		image: "/images/peppa-main.png",
		color: "#C3B2AB",
		position: "bather",
	},
	bather: {
		name: "Bather",
		image: "/images/bather-main.png",
		color: "#F3A713",
		position: "bather",
	},
}

const yesOrNoOptions = [
	{
		value: true,
		label: "Yes",
	},
	{
		value: false,
		label: "No",
	},
]

const genderOptions = [
	{
		value: "male",
		label: "Male",
	},
	{
		value: "female",
		label: "Female",
	},
]

const petTypeOptions = [
	{
		value: "dog",
		label: "Dog",
	},
	{
		value: "cat",
		label: "Cat",
	},
]

const NUM_OF_AVAILABLE_DAYCARE_SPOTS = 40

const petObj = {
	id: "",
	name: "",
	breed: "",
	type: "dog",
	age: "",
	color: "",
	gender: "",
	weight: "",
	isFixed: false,
	fixedDate: "",
	hasBordatellaVaccine: false,
	bordatellaExpDate: "",
	hasRegularVaccine: false,
	regularShotsExpireDate: "",
	hasAllergies: false,
	currentInjuries: "",
	generalBehaviour: "",
	vaccineRecordImgUrl: "",
	bordatellaImgUrl: "",
	petImg: null,
	notes: null,
	allowPhoto: null,
}

const daycarePassOptions = [
	{
		id: "HD-5",
		value: "Half Day 5 Visit pass",
		label: "Half Day 5 Visit pass $90",
		cost: 90,
		numOfVisits: 5,
		img_src: "/images/passes/daycare_5_HD.png",
		type: "HD",
	},
	{
		id: "HD-10",
		value: "Half Day 10 Visit pass",
		label: "Half Day 10 Visit pass $160",
		cost: 160,
		numOfVisits: 10,
		img_src: "/images/passes/daycare_10_HD.png",
		type: "HD",
	},
	{
		id: "HD-20",
		value: "Half Day 20 Visit pass",
		label: "Half Day 20 Visit pass $290",
		cost: 290,
		numOfVisits: 20,
		img_src: "/images/passes/daycare_20_HD.png",
		type: "HD",
	},
	{
		id: "FD-5",
		value: "Full Day 5 Visit pass",
		label: "Full Day 5 Visit pass $144",
		cost: 144,
		numOfVisits: 5,
		img_src: "/images/passes/5-day-FD.png",
		type: "FD",
	},
	{
		id: "FD-10",
		value: "Full Day 10 Visit pass",
		label: "Full Day 10 Visit pass $280",
		cost: 280,
		numOfVisits: 10,
		img_src: "/images/passes/10-day-FD.png",
		type: "FD",
	},
	{
		id: "FD-20",
		value: "Full Day 20 Visit pass",
		label: "Full Day 20 Visit pass $530",
		cost: 530,
		numOfVisits: 20,
		img_src: "/images/passes/20-day-FD.png",
		type: "FD",
	},
]

const UPDATE_FAILED = "update_failed"
const CLIENT_NOT_FOUND = "client_not_found"
const INVALID_TOKEN = "invalid_token"
const EXPIRED_TOKEN = "expired_token"
const NO_TOKEN = "no_token"
const FORMS_UPDATED_ALREADY = "updated_already"
const UPDATE_FAILED_MSG = "We could not complete your request,"
const CLIENT_NOT_FOUND_MSG = "We could not find your record,"
const INVALID_TOKEN_MSG = "Invalid token,"
const NO_TOKEN_MSG = "Request is invalid,"
const EXPIRED_TOKEN_MSG = "Your token has expired or it's invalid,"
const FORMS_UPDATED_ALREADY_MSG =
	"Your submission has already been processed; you don't need to do anything else. For assistance"

const INVALID_GROOMING_COUPON_ERROR = "Coupon Code cannot be used for grooming"
const INVALID_CODE_ERROR = "Client used an Invalid Code!"
const EXPIRED_CODE_ERROR = "Coupon Code expired!"

module.exports = {
	services,
	reviews,
	teamMembers,
	locations,
	yesOrNoOptions,
	genderOptions,
	petObj,
	petTypeOptions,
	morningStartTimes,
	afternoonStartTimes,
	allEndTimes,
	NUM_OF_AVAILABLE_DAYCARE_SPOTS,
	DAYCARE_TRIAL_TIME_MORNING,
	DAYCARE_TRIAL_TIME_AFTERNOON,
	daycarePassOptions,
	UPDATE_FAILED,
	CLIENT_NOT_FOUND,
	INVALID_TOKEN,
	EXPIRED_TOKEN,
	NO_TOKEN,
	FORMS_UPDATED_ALREADY,
	UPDATE_FAILED_MSG,
	CLIENT_NOT_FOUND_MSG,
	INVALID_TOKEN_MSG,
	NO_TOKEN_MSG,
	FORMS_UPDATED_ALREADY_MSG,
	EXPIRED_TOKEN_MSG,
	INVALID_GROOMING_COUPON_ERROR,
	INVALID_CODE_ERROR,
	EXPIRED_CODE_ERROR,
}
